<template>
  <div class="container">
    <Header/>
    <div class="wrap">
      <table class="product-table">
      <tr class="table-th">
        <tableTh width="70%">产品系列名称</tableTh>
        <tableTh width="20%">备货数量</tableTh>
        <tableTh width="10%" color="#C71E22">确认后数量</tableTh>
      </tr>
      <tr class="table-td" v-for="item in data.list" :key="item.good_code">
        <td>{{ item.goods_name }}</td>
        <td>{{ item.good_num }}</td>
        <td><input class="table-input" type="number" @blur="handleGoodsNum($event, item.good_code)" style="width: 2rem"></td>
      </tr>
    </table>
    </div>
    <div class="btn">
      <a-button class="btn-item back" size="large">
        <router-link to="/">返回</router-link>
      </a-button>
      <a-button class="btn-item confirm" type="primary" size="large" @click="showPromiseConfirm">
        确认调整
      </a-button>
    </div>
  </div>
</template>

<script>
import { createVNode, defineComponent, onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Modal, message } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { apiGetMachineDetail, apiGetMachineGoodsRenew } from '@/request/apis/Stock/StockApi'
import { tableTh } from './StyledStockDetail'
import Header from '../../components/Header/Header.vue'

export default defineComponent({
  name: 'StockMachineDetail',
  components: { Header, tableTh },
  setup () {
    const router = useRouter(); const route = useRoute()
    const data = reactive({
      list: [],
      updateNum: []
    })

    onMounted(() => {
      apiGetMachineDetail(route.params.item).then((res) => {
        data.list = res.data.data
      })
    })

    function showPromiseConfirm () {
      Modal.confirm({
        title: '确认调整',
        icon: createVNode(ExclamationCircleOutlined),
        content: '请确认是否调整当前机器备货数量~',
        async onOk () {
          try {
            return apiGetMachineGoodsRenew(route.params.item, data.updateNum).then(res => {
              if (res.status === 200) {
                message.success('调整成功')
                router.push({ name: 'TaskList' })
              } else {
                message.error('调整失败')
              }
            })
          } catch {
            return console.log('调整失败!')
          }
        }
      })
    }

    const handleGoodsNum = (event, code) => {
      if (event.target.value !== '') {
        data.updateNum.push({
          good_code: code,
          good_num: event.target.value
        })
      } else {
        // TODO 等于空时删除对应数量
      }
    }

    return { data, showPromiseConfirm, handleGoodsNum }
  }
})

</script>

<style scoped lang="scss">
@import "../../style/global";

table, th, td {
  text-align: center;
  border: 1px solid #E0E0E0;
}

.container {
  height: 100vh;

  .wrap {
    height: calc(100% - 3rem);
    overflow: auto;

    .product-table {
      font-size: .33rem;
      background-color: #fff;
      box-shadow: 0px 5px 10px 1px rgb(210 200 200 / 80%);
      overflow-x: auto;
      overflow-y: hidden;

      .table-th {
        height: 1.4rem;
        line-height: 1.4rem;
        font-weight: bold;
        font-size: .36rem;
        @include themeFontFamily();
      }

      .table-td {
        td {
          padding: .1rem;

          .table-input {
            outline-style: none;
            border: 0;
            padding: .15rem .3rem;
          }
        }
      }
    }
  }

  .btn {
    display: flex;
    justify-content: space-around;
    margin: .3rem 0;
    font-size: .3rem;
    @include themeFontFamily($fontFamilyGBT);

    .btn-item {
      width: 4.2rem;
      height: 1.3rem;
      font-size: 0.5rem;
      border-radius: 4px;
    }

    .back {
      color: #717171;
      background-color: #D1D1D1;
    }

    .confirm {
      color: rgba(255, 255, 255, 0.93);
      border: none;
      @include themeBgC();
    }
  }
}
</style>
